$darkColor1: #00618c;
$darkColor2: #04222b;
$lightColor1: #00aabb;
$lightColor2: #00b2be;
$usedgray1:#202020;
$usedgray2:#e0e0e0;
$usedgray3:#a0a0a0;

* {    
    margin: 0 ;
    padding: 0 ;
    .home{
        
        .topBar{
            background-color: rgb(22 22 23/80%);
            backdrop-filter: blur(20px);
            position: sticky;
            top: 0;
            z-index: 100;
            max-height: 100px;
            background-color: black ;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr ;
            
            img{
                margin-left: auto;
                margin-right: auto;
                margin-top: 5px;
                max-height: 75px;
            }
            .selectors{
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                button{
                    margin-top: 25px;
                    margin-left: 5px;
                    margin-right: 5px;
                    height: 50px;
                    width: 100px;
                    border-radius: 20%;
                    background-color: black;
                    color: $lightColor1;
                    border-color: $lightColor1;
                }
                button:hover{
                    background-color: $usedgray1;
                    
                }
            }
            .user{
                margin: auto;
                display: grid;
                grid-template-columns: 1fr 1fr ;
                h4{
                    margin: auto;
                    color: $lightColor1;
                }
                button{
                    margin-top: 25px ;
                    height: 50px;
                    width: 100px;
                    border-radius: 20%;
                    background-color:$lightColor1 ;
                    color: black;
                    border-color: $lightColor1;
                    padding: 10px;
                }
                button:hover{
                    background-color: $lightColor2 ;
                    
                }
                img{
                    border-radius: 50%;
                    width: 75px;
                    height: 75px;
                }
                
                
            }
        }
        .page{
            background-color: $usedgray1;

            padding: 20px;

            .Parties{
                display: grid;
                grid-template-columns: 1fr 2fr ;
                min-height: 800px;
                .left{
                    background-color: $lightColor2;
                    min-height: 800px;
                    margin-right: 20px;
                    border-radius: 20px;
                    h2{
                        text-align: center;
                        padding-top: 20px;
                    }
                    .range{
                        text-align: center;
                        padding-top: 20px;

                        input[type=range]::-webkit-slider-thumb {
                            box-shadow: 0px 0px 0px #000000;
                            border: 0px solid #000000;
                            height: 20px;
                            width: 40px;
                            border-radius: 12px;
                            background: #529DE1;
                            cursor: pointer;
                            -webkit-appearance: none;
                            margin-top: -3px;
                          }

                    }
                    .events{
                        text-align: center;
                        padding-top: 20px;
                        margin-left: auto;
                        margin-right: auto;
                        .filterType{
                            padding:10px;
                            
                            .checkBoxChunk{
                                padding: 5px;
                                
                               
                                .form-check-label{
                                    padding: 10px;
                                    background-color: $usedgray2;
                                    display: grid;
                                    grid-template-columns: 1fr 2fr ;
                                    border-radius: 5px;
                                    
                                    .form-check-input{
                                    
                                    accent-color: black;
                                    
                                    }
                                }
                            }
                        }
                    }
                }
                .right{
                    background-color: $lightColor2;
                    min-height: 800px;
                    height: fit-content;
                    border-radius: 20px;
                    padding: 5px;
                    .map{
                        height: 400px;
                        width: 90%;
                        padding: 5%;
                        .EventPop{
                            text-align: center;
                            width: min-content;
                            height: max-content;
                            .EventPic{
                                max-width: 100%;
                                max-height: 80%;
                                margin-left: auto;
                                margin-right: auto;
                            }
                            .EventInfo{
                                display: grid;
                                grid-template-columns: 1fr 1fr ;
                                text-align: center;
                                span{
                                    padding: 10px;
                                }
                            }
                        }
                    }
                    table {
                        width: 100%;
                        
                    }
                    
                    thead, tbody, tr {
                        display: table;
                        width: 100%;
                        table-layout: fixed;
                        text-align: center;
                        color: $usedgray2;
                    }
                    tr:hover{
                        background-color: $darkColor1;
                    }

                    .header:hover{
                        background-color: $lightColor2;
                        border-radius: 10px;
                    }
                    tbody {
                        display: block;
                        overflow: auto;
                        table-layout: fixed;
                        max-height: 575px;
                    }
                    tbody::-webkit-scrollbar {
                        width: 10px;
                    }
                    
                    tbody::-webkit-scrollbar-thumb {
                        background-color: $usedgray2;    /* color of the scroll thumb */
                        border-radius: 20px;       /* roundness of the scroll thumb */
                    }
                   .rows{ 
                        .FullParty{
                            padding: 10px;
                            border-radius: 10px;
                            .line{
                                border-radius: 10px;
                                
                            }
                            .react-icons {
                               color: #000000;
                            }
                            .slideDown{

                                .partyInfo{
                                    padding-top: 10px;
                                    display: grid;
                                    grid-template-columns: 1fr 1fr 1fr;
                                    height: fit-content;

                                    p{
                                        margin: auto;
                                    }
                                    button{
                                        margin: auto;
                                        
                                    }
                                    
                                }
                                .partyChat{
                                    border:5px solid black;
                                    
                                    .fullChat{
                                        padding:5px;
                                        background-color: $usedgray1;
                                        
                                        
                                        .messageHolder{
                                            overflow: auto;
                                            max-height: 300px;
                                            .mychat{
                                                max-width: 70%;
                                                background-color: $lightColor1;
                                                text-align: right;
                                                width: fit-content;
                                                margin-right: 10px;
                                                margin-left: auto;
                                                margin-top: 10px;
                                                margin-bottom: 10px;
                                                padding: 5px;
                                                border-radius: 10px 10px 0px 10px;
                                            }
                                            .theychat{
                                                background-color: $usedgray3;
                                                text-align: left;
                                                max-width: 70%;
                                                width: fit-content;
                                                margin-right: auto;
                                                margin-left: 10px;
                                                margin-top: 10px;
                                               
                                                padding: 5px;
                                                border-radius: 10px 10px 10px 0px;
                                            }
                                            .sentMarker{
                                                
                                                text-align: left;
                                                max-width: 70%;
                                                width: fit-content;
                                                margin-right: auto;
                                                margin-left: 10px;
                                                
                                                margin-bottom: 10px;
                                                padding: 5px;
                                                border-radius: 10px 10px 10px 0px;
                                            }
                                        }
                                        .chatInput{
                                            
                                            align-items: center;
                                            input {
                                                min-height: 50px;
                                                max-width: 150px;
                                                padding: 0 1rem;
                                                color: $usedgray2;
                                                font-size: 15px;
                                                border: 1px solid $lightColor1;
                                                border-radius: 6px 0 0 6px;
                                                background-color: transparent;
                                            }
                                            button {
                                                min-height: 50px;
                                                padding: .5em 1em;
                                                border: none;
                                                border-radius: 0 6px 6px 0;
                                                background-color: $lightColor1;
                                                color: $usedgray2;
                                                font-size: 15px;
                                                cursor: pointer;
                                                transition: background-color .3s ease-in-out;
                                            }
                                            button:hover {
                                                background-color: $darkColor1;
                                            }
                                            input:focus, input:focus-visible {
                                                border-color: $darkColor1;
                                                outline: none;
                                            }
                                            
                                        }
                                    }
                                    ::-webkit-scrollbar {
                                        width: 10px;
                                    }
                                    
                                    ::-webkit-scrollbar-thumb {
                                        background-color: $usedgray2;    /* color of the scroll thumb */
                                        border-radius: 20px;       /* roundness of the scroll thumb */
                                    }
                                }
                                
                            }
                        }
                   }
                }
            }

            .create{
                
                .Form{
                    margin-left: auto;
                    margin-right: auto;
                    background-color: $lightColor2;
                    text-align: center;
                    align-items: center;
                    width: 50%;
                    border-radius: 20px;
                    padding: 15px;
                    
                    select{
                        display: block;
                        font-size:20px;
                        padding:4px 2px;
                        border:solid 1px #aacfe4;
                        width:90%;
                        margin:2px 0 20px 10px;
                        margin-right: auto;
                        margin-left: auto;
                    }
                    label{
                        font-size: 30px;
                    }
                    input{
                        display: block;
                        font-size:20px;
                        padding:4px 2px;
                        border:solid 1px #aacfe4;
                        width:90%;
                        margin:2px 0 20px 10px;
                        margin-right: auto;
                        margin-left: auto;
                    }
                    textarea{
                        display: block;
                        font-size:20px;
                        padding:4px 2px;
                        border:solid 1px #aacfe4;
                        width:90%;
                        margin:2px 0 20px 10px;
                        margin-right: auto;
                        margin-left: auto;
                    }
                    button{
                        margin-top: 20px;
                        height: 30px;
                        width: 200px;
                    }
                }
            }

            .Friends{
                display: grid;
                grid-template-columns: 1fr 1fr ;
                min-height: 800px;
                .left{
                    background-color: $lightColor2;
                    min-height: 800px;
                    margin-right: 20px;
                    border-radius: 20px;
                    h1{
                        text-align: center;
                    }
                    .requests{
                        .request{
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr 1fr;
                        height: 100px;
                            img{
                                padding:10%;
                                max-width: 80%;
                                max-height: 80%;
                            }
                            p{
                                margin: auto;
                            }
                            button{
                                height: 50%;
                            }
                        }
                    }
                    .people{
                        .person{
                            display: grid;
                            grid-template-columns: 1fr 2fr 1fr;
                            height: 100px;
                            img{
                                object-fit: cover;
                                width: 80px;
                                height: 80px;
                                margin: auto;
                                max-width: 80%;
                                max-height: 80%;
                                border-radius: 50%;
                            }
                            p{
                                margin: auto;
                            }
                            button{
                                margin: auto;
                                margin-right: 10px;
                            }
                        }
                    }
                    
                }
                .right{
                    background-color: $lightColor2;
                    height: 800px;
                    border-radius: 20px;
                    .add{
                        img{
                            object-fit: cover;
                                width: 80px;
                                height: 80px;
                                margin: auto;
                                max-width: 80%;
                                max-height: 80%;
                                border-radius: 50%;
                        }
                    }
                    h1{
                        text-align: center;
                    }

                }
            } 

            .Settings{
                display: grid;
                grid-template-columns: 1fr 1fr 1fr ;
                min-height: 800px;
                .Setting{
                    background-color: $lightColor2;
                    padding: 20px;
                    margin: 20px;
                    height: 200px;
                    border-radius: 10px;
                    img{
                            margin-left: auto;
                            margin-right: auto;
                            margin-top: 5px;
                            max-height: 75px;
                            max-width: 75px;
                            border-radius: 50%;
                        }
                    form{
                        input{
                            color: $usedgray1;
                        }
                        button{
                            margin-top: 25px ;
                            height: 50px;
                            width: 100px;
                            border-radius: 20%;
                            background-color:$darkColor1 ;
                            color: black;
                            border-color: $darkColor2;
                            padding: 10px;
                        }
                        
                    }
                }
            }

            .Review{
                min-height: 800px;
                .Form{
                    margin-left: auto;
                    margin-right: auto;
                    background-color: $lightColor2;
                    text-align: center;
                    align-items: center;
                    width: 50%;
                    border-radius: 20px;
                    padding: 15px;
                    
                    select{
                        display: block;
                        font-size:20px;
                        padding:4px 2px;
                        border:solid 1px #aacfe4;
                        width:90%;
                        margin:2px 0 20px 10px;
                        margin-right: auto;
                        margin-left: auto;
                    }
                    label{
                        font-size: 30px;
                    }
                    input{
                        display: block;
                        font-size:20px;
                        padding:4px 2px;
                        border:solid 1px #aacfe4;
                        width:90%;
                        margin:2px 0 20px 10px;
                        margin-right: auto;
                        margin-left: auto;
                    }
                    textarea{
                        display: block;
                        font-size:20px;
                        padding:4px 2px;
                        border:solid 1px #aacfe4;
                        width:90%;
                        margin:2px 0 20px 10px;
                        margin-right: auto;
                        margin-left: auto;
                    }
                    button{
                        margin-top: 20px;
                        height: 30px;
                        width: 200px;
                    }
                }
            }
        }
        
        .information{
            background-color: black;
            
            min-height: 100px;
            color: $usedgray3;
            .infoTop{
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                gap: 10px;
                min-height: 100px;
            
                .left{
                    text-align: center;
                    h3{
                        text-align: center;
                        text-decoration: underline;
                        margin: 10px;
                    }
                    
                }
                .middle{
                    text-align: center;
                    h3{
                        
                        text-align: center;
                        text-decoration: underline;
                        margin: 10px;
                    }
                }
                .right{
                    text-align: center;
                    h3{
                        
                        text-align: center;
                        text-decoration: underline;
                        margin: 10px;
                    }
                    button{
                        padding: 10px;
                        color: $usedgray3;
                        padding: 0;
                        border: none;
                        background: none;
                    }
                    button:hover{
                        color: $usedgray2;
                        background-color: $usedgray1;
                        border-radius: 5px;
                    }
                }
            }
            .infoBot{
                text-align: center;
                
            }
        }

        
    }
}


.landing{
    
    .topBar{
        position: sticky;
        top: 0;
        background: #000;
        width: calc(100% - 20px);
        z-index: 100;
        padding: 10px;
        text-align: right;
        height: fit-content;
        min-height: 80px;
        
        
        
        img{
            
            height: 76px;
            float: left;
        }
        p{
            
            color: #FFF;
        
            font-size: 20px;
            padding-top: 30px;
            padding-right: 20px;
            
        }
    }
    main{
        min-height: 1000px;
        .focus{
            display: flex;
            
                
            .home_banner1{
                max-height: 700px;
                display: flex;
                width: 100%;
                
            }
            
            
            @media screen and (max-width: 1130px) {
                .home_banner1 {
                  display: none;
                }
            }
            .fade{
                margin: auto;
                
                .formWrapper{
                padding: 10px;
                height: fit-content;                
                h3{
                    text-align: center;
                }
                form{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 18px;
                    align-self: stretch;
                    
                    .fullIn{
                        border-radius: 8px;
                        border: 1px solid #8D9298;
                        background: #FFF;
                        display: flex;
                        align-items: center;
                        align-self: stretch;
                        .left{
                            display: flex;
                            padding: 12px 0px 12px 12px;
                        }
                        .right{
                            display: flex;
                            padding: 12px;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 4px;
                            flex: 1 0 0;
                            input{
                                color: #495059;
                                font-family: Helvetica Neue;
                                font-size: 20px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 18px; /* 128.571% */
                                border:none;
                            }
                        }
                    }
                    .logButton{
                        display: flex;
                        width: 100%;
                        padding: 14px 0px;
                        justify-content: center;
                        align-items: center;
                        border-radius: 4px;
                        background: #197AFF;
                        color: white;
                        border: none;
                        margin-bottom: 10px;
                        cursor:pointer;
                    }
                }
                .hr-text {
                    line-height: 1em;
                    position: relative;
                    outline: 0;
                    border: 0;
                    color: black;
                    text-align: center;
                    height: 1.5em;
                    opacity: 0.5;
                    &:before {
                      content: '';
                      // use the linear-gradient for the fading effect
                      // use a solid background color for a solid bar
                      background: linear-gradient(to right, transparent, #818078, transparent);
                      position: absolute;
                      left: 0;
                      top: 50%;
                      width: 100%;
                      height: 1px;
                    }
                    &:after {
                      content: attr(data-content);
                      position: relative;
                      display: inline-block;
                      color: black;
                        
                      padding: 0 0.5em;
                      line-height: 1.5em;
                      // this is really the only tricky part, you need to specify the background color of the container element...
                      color: #818078;
                      background-color: #fcfcfa;
                    }
                }
                .signUpButton{
                    display: flex;
                    width: 100%;
                    padding: 14px 0px;
                    justify-content: center;
                    align-items: center;
                    border-radius: 4px;
                    border: 2px solid #197AFF;
                    background-color: white;
                    color: #197AFF;
                    cursor:pointer;
                }
            }
            }
            
            
        }
        .home_banner2{
                
            display: none;
        }
        @media screen and (max-width: 1130px) {
            .home_banner2 {
                display: block;
                margin-left: auto;
                margin-right: auto;
                width: 100%;
                
            }
        }
        .pills{
            .pill{
                border-radius: 30px;
                margin: 5%;
                border: solid #FFC862 2px;
                display: grid;
                grid-template-columns: 1fr 1fr;
                overflow: hidden;
                .text{
                    padding: 10%;
                    background-color: #FFC862;
                    font-size: 25px;
                    h2{
                        margin-bottom: 10px;
                    }
                    p{
                        color: white;
                    }
                }
                .picture{
                    img{
                        padding: 10%;
                        max-height: 80%;
                        max-width: 80%;
                    }
                }
            }
        }

    }
    .botBar{
        height: 78px;
        background: #000;
        width: 100%;
    }
}

